import userService from '../services/userService';
import { getAuthCookie, removeAuthCookie } from './cookieUtils';

export const checkAuth = async () => {
  const authCookie = getAuthCookie();
  if (!authCookie) {
    return;
  }

  try {
    const response = await userService.getAuth(authCookie);
    if (!response.auth_token.is_token_valid) {
      removeAuthCookie();
      return;
    }

    return response;
  } catch (error) {
    removeAuthCookie();
  }
};
