import { useContext, useEffect } from 'react';

import * as S from './styles';
import Icon from '../../atoms/Icon';
import { MachineContext } from '../../../context';
import Header from '../../molecules/Header';
import EmailForm from '../../templates/EmailForm';
import { colors } from '../../../styles/colors';
import { useState } from 'react';
import PasswordForm from '../../templates/PasswordForm';
import RegisterForm from '../../templates/RegisterForm';
import cat from '../../../assets/cat.png';
import sheep from '../../../assets/sheep.png';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import PrivacyPolicy from '../../templates/PrivacyPolicy';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';
import { localStorageUtils } from '../../../utils/localStorageUtils';
import { pageAddress, storeKeys } from '../../../utils/constants';

export function Login() {
  const { updateUserInfo, modalScreen, setModalScreen } =
    useContext(MachineContext);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');

  useEffect(() => {
    localStorageUtils.SetItem(storeKeys.LAST_PAGE_VISITED, pageAddress.login)
  }, [])

  const onEmailSubmit = (isRegistered: boolean) => {
    setStep(isRegistered ? 2 : 3);
    updateUserInfo('email', email);
  };

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            {step === 3 && (
              <S.StyledBackButton isSamePage onClick={() => setStep(1)} />
            )}
            {step !== 3 && (
              <>
                <S.ImageContainer>
                  <S.StyledBackButton
                    isSamePage={step !== 1}
                    onClick={() => setStep(1)}
                  />
                  <S.TitleContainer>
                    <div>
                      {step === 3 && <span>Olá,</span>}
                      <span>{step === 3 && 'É bom te ver por aqui!'}</span>
                    </div>
                  </S.TitleContainer>
                </S.ImageContainer>
                <S.ImageWrapper widthPercent={step === 1 ? 43 : 60}>
                  {step === 1 && (
                    <S.FirstScreenImages>
                      <img src={cat} alt="pork" />
                      <img src={sheep} alt="sheep" />
                    </S.FirstScreenImages>
                  )}
                  {step === 2 && (
                    <S.SecondScreenImages>
                      <Icon type="security" />
                    </S.SecondScreenImages>
                  )}
                </S.ImageWrapper>
              </>
            )}
            {step === 1 && (
              <EmailForm
                nextStep={onEmailSubmit}
                email={email}
                setEmail={setEmail}
              />
            )}
            {step === 2 && (
              <PasswordForm prevStep={() => setStep(1)} email={email} />
            )}
            {step === 3 && (
              <RegisterForm prevStep={() => setStep(1)} email={email} />
            )}
          </S.Container>
        </div>
      </MenuDrawer>

      <Modal
        className={`react-modal-content`}
        isOpen={!!modalScreen}
        onRequestClose={() => setModalScreen('')}
        overlayClassName="react-modal-overlay"
      >
        <div className="close-button" onClick={() => setModalScreen('')}>
          <AiOutlineClose size="24px" color="#00000099" />
        </div>
        {modalScreen === 'policy' && <PrivacyPolicy />}
      </Modal>
    </>
  );
}

export default Login;
