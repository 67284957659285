export enum storeKeys {
  AUTKS = '__UAUTKS',
  LAST_PAGE_VISITED = 'lastPageVisited',
  IS_USER_LOGGED = 'isUserLogged',
}

export enum pageAddress {
  login = '/login',
  menu = '/menu',
  wallet = '/wallet',
  rescue = '/rescue',
  about = '/about',
  joinGame = '/join',
  account = '/account',
  recover = '/recover',
}

export enum SocketGameStatus {
  Ready = 'ready_to_play',
  Waiting = 'waiting',
  Playing = 'playing',
}
