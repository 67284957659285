import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';

import { VscBookmark, VscGift, VscReactions } from 'react-icons/vsc';

import { useContext, useEffect, useState } from 'react';
import Button from '../../atoms/Button';
import { MachineContext } from '../../../context';
import { useNavigate } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import gameService from '../../../services/gameService';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';
import { Alert, Snackbar } from '@mui/material';
import { SocketGameStatus } from '../../../utils/constants';

export function JoinGame() {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [playsSelected, setPlaysSelected] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const {
    setStatus,
    setShowDesktopMenu,
    setPlays,
    user,
    setWaitingList,
    setCurrentPlayerName,
    setGameToken,
    setMaxMoves,
    maxMoves,
  } = useContext(MachineContext);

  useEffect(() => {
    async function fetchData() {
      const data = await gameService.getMaxMovesAvailable();
      if (data.available_moves > 0) {
        setMaxMoves(data.available_moves);
      }
    }

    fetchData();
  }, []);

  const onHandleClick = async () => {
    setShowDesktopMenu(false);
    setMessage('');
    try {
      setIsLoading(true);
      const response = await gameService.postJoinGame(
        playsSelected,
        user.id,
        user.name
      );
      setPlays(response.moves);
      if (
        response.position === 0 &&
        response.game_token &&
        response.game_status === SocketGameStatus.Ready
      ) {
        setGameToken(response.game_token);
        setStatus('R');
      } else {
        setStatus('W');
        setWaitingList(response.position);
        setCurrentPlayerName(response.current_player_name);
      }
      navigation('/');
    } catch (error: any) {
      setMessage(error.response.data.message);
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPlaysSelected(Number(event.target.value));
  };

  const limit =
    user.amount / user.movePrice > maxMoves
      ? maxMoves
      : user.amount / user.movePrice;

  const options = Array.from({ length: limit }, (_, index) => (
    <MenuItem
      key={index}
      selected={playsSelected === index + 1}
      onClick={() => setPlaysSelected(index + 1)}
      value={index + 1}
    >
      {index + 1}
    </MenuItem>
  ));

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.ImageContainer>
              <S.StyledBackButton />
              <S.TitleContainer>
                <h2>Entrar na Fila</h2>
                <span>Olá, {user.name}</span>
                <p>
                  1 Jogada ={' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(user.movePrice || 0)}
                </p>
              </S.TitleContainer>
            </S.ImageContainer>
            <S.Content>
              <S.MenuItem shadow={false}>
                <div>
                  <VscBookmark size={24} />
                  <span>Saldo na Carteira</span>
                </div>
                <h3>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(user.amount || 0)}
                </h3>
              </S.MenuItem>
              <S.MenuItem shadow={false}>
                <div>
                  <VscGift size={24} />
                  <span>Minhas pelúcias</span>
                </div>
                <h3>{user.plushQtd}</h3>
              </S.MenuItem>
              <S.SelectContainer>
                <div>
                  <VscReactions size={24} />
                  <span>Selecionar jogadas</span>
                </div>
                {options.length > 0 && (
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={
                      playsSelected.toString() === '0'
                        ? ''
                        : playsSelected.toString()
                    }
                    onChange={handleChange}
                    label="Quantidade"
                  >
                    {options}
                  </Select>
                )}
              </S.SelectContainer>
              {options.length === 0 && (
                <S.NoOptions>
                  Você não possui saldo suficiente para fazer um jogo,{' '}
                  <button onClick={() => navigation('/wallet')}>
                    adicione saldo
                  </button>{' '}
                  para continuar
                </S.NoOptions>
              )}
              <Alert
                variant="outlined"
                severity="info"
                sx={{ border: 'none', padding: '0' }}
              >
                Em caso da máquina entrar em abastecimento durante a sua jogada,
                você será desconectado e as jogadas que não tenham sido
                concluídas retornarão à sua carteira automaticamente.
              </Alert>
              <Button
                onClick={onHandleClick}
                disabled={!playsSelected || isLoading}
              >
                Entrar
              </Button>
            </S.Content>
          </S.Container>
        </div>
      </MenuDrawer>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default JoinGame;
