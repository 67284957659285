import { storeKeys } from './constants';


export const getAuthCookie = () => {
  const cookies = document.cookie.split(';');
  const cookieByName = cookies.find((item) =>
    item.trim().startsWith(storeKeys.AUTKS)
  );

  if (!cookieByName) return null;

  return cookieByName.trim().split('=')[1];
};

export const setAuthCookie = (token: string) => {
  if (!token) return;

  const expires = new Date();
  expires.setDate(expires.getDate() + 10);
  document.cookie = `${storeKeys.AUTKS}=${token};expires=${expires.toUTCString()};path=/`;
};

export const removeAuthCookie = () => {
  const expires = new Date();
  expires.setDate(expires.getDate() - 1);
  document.cookie = `${storeKeys.AUTKS}=;expires=${expires.toUTCString()};path=/`;
};
