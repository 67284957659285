import Button from '../../atoms/Button';
import Input from '../../atoms/NewInput';
import LoginForm from '../../organisms/LoginForm';
import { useContext, useEffect, useState } from 'react';
import { PasswordFormProps } from './types';

import * as S from './styles';
import { MachineContext } from '../../../context';
import { useNavigate } from 'react-router-dom';
import userService from '../../../services/userService';
import { errorHandler } from '../../../services/api';
import { setAuthCookie } from '../../../utils/cookieUtils';
import { TitleContainer } from '../../organisms/LoginForm/styles';

export function PasswordForm({ email, prevStep }: PasswordFormProps) {
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const { updateUserInfo, setWaitingList } = useContext(MachineContext);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setHasError(false);
  }, [password]);

  const onSubmitEmail = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await userService.postResendCode(email, 'reset_password');
      navigate('/recover?email=' + email);
    } catch (error) {
      alert(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleClick = async () => {
    setIsLoading(true);
    try {
      const response = await userService.postLogin(email, password);
      updateUserInfo('id', response.player_id);
      updateUserInfo('name', response.name);
      updateUserInfo('amount', response.wallet.available_money);
      updateUserInfo('isLogged', true);
      updateUserInfo('victoryAmount', response.wallet.victory_money);
      updateUserInfo('plushQtd', response.wallet.plush_to_recover);
      updateUserInfo('plushPrice', response.game_info.plush_price);
      updateUserInfo('movePrice', response.game_info.move_price);
      setAuthCookie(response.token_string);

      setWaitingList(response.quantity_of_players_waiting);
      navigate('/');
    } catch (error) {
      alert(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginForm>
      <TitleContainer>
        <h3>É bom te ver novamente!</h3>
        <span>{email}</span>
      </TitleContainer>
      <div style={{ display: 'flex' }}>
        <S.Anchor style={{ marginTop: '-16px' }} onClick={prevStep}>
          Usar outro endereço de e-mail
        </S.Anchor>
      </div>
      <Input
        type="password"
        placeholder="Senha"
        name="password"
        value={password}
        onChange={setPassword}
        hasError={hasError}
        errorMessage="Senha incorreta"
      />
      <S.ForgotPassword>
        <S.Anchor disabled={isLoading} onClick={onSubmitEmail}>
          Esqueceu a senha?
        </S.Anchor>
      </S.ForgotPassword>
      <Button
        onClick={() => {
          onHandleClick();
        }}
        color="#56A8E7"
        border={false}
        disabled={hasError || !password || isLoading}
      >
        Entrar
      </Button>
    </LoginForm>
  );
}

export default PasswordForm;
