import { getAuthCookie } from '../utils/cookieUtils';
import { api } from './api';

import * as types from './gameService.types';

const gameService = {
  postJoinGame: async (moves: number, userId: string, username: string) => {
    const request = await api.post<types.PostJoinGameResponse>(
      `/socket/play/token`,
      {
        moves: moves,
        player_id: userId,
        username,
      },
      {
        headers: {
          authorization: getAuthCookie(),
        },
      }
    );

    return request.data;
  },

  getGameStatus: async (userId: string) => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
      params: { player_id: userId },
    };

    const request = await api.get<types.PostJoinGameResponse>(
      `/internal/player/game-status`,
      config
    );

    return request.data;
  },

  postGameRefund: async (userId: string, token: string, moves: number) => {
    const request = await api.post<types.PostJoinGameResponse>(
      `/internal/socket/refund`,
      {
        remaining_moves: moves,
        player_id: userId,
        game_token: token,
      },
      {
        headers: {
          authorization: getAuthCookie(),
        },
      }
    );

    return request.data;
  },

  getMaxMovesAvailable: async () => {
    const request = await api.get<types.MaxMovesResponse>(
      `socket/max_moves/available`,
      {
        headers: {
          authorization: getAuthCookie(),
        },
      }
    );

    return request.data;
  },
};

export default gameService;
