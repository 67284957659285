import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';

import { VscBookmark, VscEmptyWindow, VscGift } from 'react-icons/vsc';
import { useContext, useEffect, useState } from 'react';
import Button from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { MachineContext } from '../../../context';
import userService from '../../../services/userService';
import { errorHandler } from '../../../services/api';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';
import { RedirectContainer } from '../Checkout/styles';
import Icon from '../../atoms/Icon';
import { localStorageUtils } from '../../../utils/localStorageUtils';
import { pageAddress, storeKeys } from '../../../utils/constants';
import gameService from '../../../services/gameService';

export function Wallet() {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const {
    user,
    setCopyAndPastePix,
    setPixQrCode,
    setTransactionIds,
    updateUserInfo,
    setMaxMoves,
  } = useContext(MachineContext);

  const navigate = useNavigate();

  useEffect(() => {
    localStorageUtils.SetItem(storeKeys.LAST_PAGE_VISITED, pageAddress.wallet);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = await gameService.getMaxMovesAvailable();
      if (data.available_moves > 0) {
        setMaxMoves(data.available_moves);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // TODO fazer um if para somente pegar apos pagamento pendente muda
    const getAmount = async () => {
      return await userService.getWallet(user.id);
    };

    setIsLoadingWallet(true);
    getAmount()
      .then((response) => {
        updateUserInfo('amount', response.available_money);
        updateUserInfo('plushQtd', response.plush_to_recover);
        updateUserInfo('victoryAmount', response.victory_money);
      })
      .finally(() => {
        setIsLoadingWallet(false);
      });
  }, [updateUserInfo, user.id]);

  const onHandleClick = async () => {
    setIsLoading(true);
    try {
      const response = await userService.postAddCredit(value, user.id);
      setCopyAndPastePix(response.copy_paste);
      setPixQrCode(response.qrcode);
      setTransactionIds((prev) => [
        ...prev,
        {
          transactionId: response.transaction_id,
          already_confirmed: false,
          cancelled: false,
        },
      ]);

      navigate('/checkout/' + value);
    } catch (error) {
      alert(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.ImageContainer>
              <S.StyledBackButton showInitialButton />
              <S.TitleContainer>
                <h2>Carteira</h2>
                <span>Olá, {user.name}</span>
                <p>
                  1 Jogada ={' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(user.movePrice || 0)}
                </p>
              </S.TitleContainer>
            </S.ImageContainer>
            <S.Content>
              <S.MenuItem shadow={false}>
                <div>
                  <div>
                    <VscBookmark size={24} />
                    <span>Saldo em crédito</span>
                  </div>
                  <h3>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(isLoadingWallet ? 0 : user.amount || 0)}
                  </h3>
                </div>
              </S.MenuItem>
              <S.MenuItem shadow={false}>
                <div>
                  <div>
                    <VscGift size={24} />
                    <span>Minhas pelúcias</span>
                  </div>
                  <h3>{isLoadingWallet ? 0 : user.plushQtd}</h3>
                </div>
              </S.MenuItem>
              <S.SelectContainer>
                <VscEmptyWindow size={24} />
                <span>Adicionar saldo</span>
              </S.SelectContainer>
              <S.MenuItem shadow>
                <h5>Informe o valor</h5>
                <S.ChooseValueDiv>
                  <div>
                    <span>
                      R${' '}
                      <input
                        type="number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValue(e.target.valueAsNumber);
                        }}
                        placeholder="18"
                      />
                    </span>
                  </div>
                </S.ChooseValueDiv>
              </S.MenuItem>

              <S.StyledAlert>
                O valor mínimo da recarga é o valor de 1 jogada. Sugerimos que
                você adicione um valor múltiplo deste para facilitar suas
                jogadas.
              </S.StyledAlert>
              <Button
                onClick={onHandleClick}
                disabled={!value || isLoading || value < user.movePrice}
              >
                Continuar
              </Button>
            </S.Content>
            <RedirectContainer style={{ marginTop: 40 }}>
              <a
                href="https://www.alabuta.com/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon type="signature" />
              </a>
            </RedirectContainer>
          </S.Container>
        </div>
      </MenuDrawer>
    </>
  );
}

export default Wallet;
