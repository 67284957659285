import { useContext, useEffect } from 'react';
// import useSound from 'use-sound';
import Modal from 'react-modal';
import * as S from './styles';
import CamContainer from '../../molecules/CamContainer';
import { OutScreen } from '../../templates/OutScreen';
import { MachineContext } from '../../../context';
import { InteractScreen } from '../../templates/InteractScreen';
import { useSocket } from '../../../hooks/socket';
import { colors } from '../../../styles/colors';
import Header from '../../molecules/Header';
import Icon from '../../atoms/Icon';
import { AiOutlineClose } from 'react-icons/ai';

// import start from '../../../assets/start.mp3';
import PaymentSuccess from '../../templates/PaymentSuccess';
import RescueMoneySuccess from '../../templates/RescueMoneySuccess';
import FeedbackContainer from '../../molecules/FeedbackContainer';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';
import ExchangeSuccess from '../../templates/ExchangeSuccess';
import RescuePlushSuccess from '../../templates/RescuePlushSuccess';
import { SocketGameStatus } from '../../../utils/constants';
import gameService from '../../../services/gameService';

Modal.setAppElement('#root');

export function Home() {
  const {
    status,
    setStatus,
    setSocket,
    user,
    setGameToken,
    gameToken,
    modalScreen,
    setModalScreen,
    feedback,
    setFeedback,
    showDesktopMenu,
    updateUserInfo,
    videoIsLoaded,
    setPlays,
    startGame,
    setWaitingList,
    setCurrentPlayerName,
    setSeconds,
    setMaxMoves,
  } = useContext(MachineContext);

  useEffect(() => {
    if (user.is_on_game_queue) {
      setPlays(user.remaining_moves);
      if (
        user.game_status === SocketGameStatus.Ready ||
        user.game_status === SocketGameStatus.Playing
      ) {
        setGameToken(user.game_token);
        setStatus('R');
        setSeconds(30);
      } else {
        setStatus('W');
        setWaitingList(user.position);
        setCurrentPlayerName(user.current_player_name);
      }
    }
  }, [
    setCurrentPlayerName,
    setGameToken,
    setPlays,
    setStatus,
    setWaitingList,
    setSeconds,
    user.current_player_name,
    user.game_status,
    user.game_token,
    user.is_on_game_queue,
    user.position,
    user.remaining_moves,
  ]);

  const isPlaying = status === 'P';
  const isOut = status === 'O';
  const isWaiting = status === 'W';
  const isReady = status === 'R';

  // const [play] = useSound(start);

  const validToken = gameToken;
  const isReadyToPlay = user.isLogged && (isReady || isPlaying) && !!validToken;

  useSocket(
    setSocket,
    isReadyToPlay,
    validToken,
    setStatus,
    setFeedback,
    user.id,
    updateUserInfo,
    setPlays,
    startGame
  );

  useEffect(() => {
    if (isPlaying && videoIsLoaded) {
      // play();
      startGame(60);
    }
  }, [isPlaying, startGame, videoIsLoaded]);

  useEffect(() => {
    async function fetchData() {
      if (user.isLogged) {
        const data = await gameService.getMaxMovesAvailable();
        if (data.available_moves > 0) {
          setMaxMoves(data.available_moves);
        }
      }
    }

    fetchData();
  }, [setMaxMoves, user.isLogged]);

  return (
    <>
      <Header
        backgroundColor={colors.blue}
        showMenu={!(isReady || isPlaying)}
      />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.Content showMenu={showDesktopMenu}>
              {feedback !== '' && <FeedbackContainer type={feedback} />}
              {feedback === '' && (
                <>
                  <CamContainer />
                  <S.InteractContainer>
                    {isOut && <OutScreen />}
                    {(isPlaying || isWaiting || isReady) && <InteractScreen />}
                    {!(isPlaying || isReady) && (
                      <S.RedirectContainer>
                        <a
                          href="https://www.alabuta.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon type="signature" />
                        </a>
                      </S.RedirectContainer>
                    )}
                  </S.InteractContainer>
                </>
              )}
            </S.Content>
          </S.Container>
        </div>
      </MenuDrawer>
      <Modal
        className={`react-modal-content`}
        isOpen={!!modalScreen}
        onRequestClose={() => setModalScreen('')}
        overlayClassName="react-modal-overlay"
      >
        <S.CloseButton onClick={() => setModalScreen('')}>
          <AiOutlineClose size="24px" color="#00000099" />
        </S.CloseButton>
        {modalScreen === 'payment' && <PaymentSuccess />}
        {modalScreen === 'rescue' && <RescueMoneySuccess />}
        {modalScreen === 'exchange' && <ExchangeSuccess />}
        {modalScreen === 'rescuePlush' && <RescuePlushSuccess />}
      </Modal>
    </>
  );
}

export default Home;
