import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';

import { VscGift, VscPackage } from 'react-icons/vsc';

import AddressForm from '../../templates/AddressForm';
import { useContext, useEffect, useState } from 'react';
import Resume from '../../templates/Resume';
import { MachineContext } from '../../../context';
// import RescueMoney from '../../templates/RescueMoney';
import userService from '../../../services/userService';
import Menu from '../Menu';
import MenuDrawer from '../../molecules/MenuDrawer';
import Exchange from '../../templates/Exchange';
import { Alert } from '@mui/material';
import { localStorageUtils } from '../../../utils/localStorageUtils';
import { pageAddress, storeKeys } from '../../../utils/constants';

export function Rescue() {
  const { user, updateUserInfo } = useContext(MachineContext);
  const [showResume, setShowResume] = useState(false);
  const [resumeWordings, setResumeWordings] = useState<Record<string, string>>(
    {}
  );
  const [rescueType, setRescueType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    localStorageUtils.SetItem(storeKeys.LAST_PAGE_VISITED, pageAddress.rescue)
  }, [])

  useEffect(() => {
    setShowResume(false);
  }, [rescueType]);

  useEffect(() => {
    const getAmount = async () => {
      return await userService.getWallet(user.id);
    };

    setIsLoading(true);
    getAmount()
      .then((response) => {
        updateUserInfo('amount', response.available_money);
        updateUserInfo('plushQtd', response.plush_to_recover);
        updateUserInfo('victoryAmount', response.victory_money);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [updateUserInfo, user.id]);

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.ImageContainer>
              <S.StyledBackButton
                showInitialButton
                {...(showResume && {
                  onClick: () => {
                    setShowResume(false);
                    setRescueType('');
                  },
                  isSamePage: true,
                })}
              />
              <S.TitleContainer>
                <h2>Minhas pelúcias</h2>
                <span>Olá, {user.name}</span>
              </S.TitleContainer>
            </S.ImageContainer>
            <S.Content>
              <S.MenuItem shadow={false}>
                {!showResume && (
                  <div>
                    <VscGift size={24} />
                    <span>Pelúcias para resgate</span>
                  </div>
                )}
                {showResume && (
                  <div>
                    <VscPackage size={24} />
                    <span>Pelúcias para envio</span>
                  </div>
                )}
                <h3>{isLoading ? 0 : user.plushQtd}</h3>
              </S.MenuItem>
              {!showResume && (
                <S.MenuItem shadow={false}>
                  {!showResume && (
                    <div>
                      <VscGift size={24} />
                      <span>Valor para resgate</span>
                    </div>
                  )}
                  <h3>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(isLoading ? 0 : user.victoryAmount || 0)}
                  </h3>
                </S.MenuItem>
              )}
              <div>
                {user.plushQtd > 0 && !isLoading && !showResume && (
                  <S.ChooseValueLabel htmlFor="plush">
                    <input
                      type="radio"
                      id="plush"
                      name="value"
                      onClick={() => setRescueType('plush')}
                    />
                    <span>Resgatar pelúcias</span>
                  </S.ChooseValueLabel>
                )}
                {/* {user.plushQtd > 0 && !isLoading && (
                  <S.ChooseValueLabel htmlFor="money">
                    <input
                      type="radio"
                      id="money"
                      name="value"
                      onClick={() => setRescueType('money')}
                    />
                    <span>Resgatar valor</span>
                  </S.ChooseValueLabel>
                )} */}
                {user.plushQtd > 0 && !isLoading && !showResume && (
                  <S.ChooseValueLabel htmlFor="exchange">
                    <input
                      type="radio"
                      id="exchange"
                      name="value"
                      onClick={() => setRescueType('exchange')}
                    />
                    <span>Trocar pelúcias por saldo na carteira</span>
                  </S.ChooseValueLabel>
                )}
              </div>
              {open && !showResume && (
                <Alert
                  onClose={() => setOpen(false)}
                  severity="info"
                  variant="filled"
                  sx={{ maxWidth: '728px' }}
                >
                  Você pode resgatar as pelúcias acumuladas OU trocar por saldo.
                  Acima é possível conferir o número de pelúcias e o saldo
                  correspondente.
                </Alert>
              )}
              {!showResume && rescueType === 'plush' && (
                <>
                  <h4>Adicione o endereço para envio</h4>
                  <AddressForm
                    onSubmit={() => setShowResume(true)}
                    setResumeWordings={setResumeWordings}
                  />
                </>
              )}
              {/* {!showResume && rescueType === 'money' && (
                <>
                  <RescueMoney />
                </>
              )} */}
              {!showResume && rescueType === 'exchange' && (
                <>
                  <Exchange />
                </>
              )}
              {showResume && rescueType === 'plush' && (
                <Resume resumeWordings={resumeWordings} />
              )}
            </S.Content>
          </S.Container>
        </div>
      </MenuDrawer>
    </>
  );
}

export default Rescue;
