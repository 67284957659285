import * as S from './styles';
import Header from '../../molecules/Header';
import { colors } from '../../../styles/colors';

import Icon from '../../atoms/Icon';
import WppSupport from '../../atoms/WppSupport';
import MenuDrawer from '../../molecules/MenuDrawer';
import Menu from '../Menu';
import { FcCollaboration, FcInvite } from 'react-icons/fc';
import Instagram from '../../../assets/Instagram.svg';
import Tiktok from '../../../assets/Tiktok.svg';
import { useEffect } from 'react';
import { localStorageUtils } from '../../../utils/localStorageUtils';
import { pageAddress, storeKeys } from '../../../utils/constants';

export function About() {
  useEffect(() => {
    localStorageUtils.SetItem(storeKeys.LAST_PAGE_VISITED, pageAddress.about)
  }, [])

  return (
    <>
      <Header backgroundColor={colors.blue} />
      <MenuDrawer>
        <div>
          <Menu />
        </div>
        <div>
          <S.Container>
            <S.ImageContainer>
              <S.StyledBackButton showInitialButton />
              <S.TitleContainer>
                <h2>Quem somos</h2>
                <span>
                  Na OnlineMachine, somos líderes e referência em entretenimento
                  a distância.
                </span>
              </S.TitleContainer>
            </S.ImageContainer>
            <S.Content>
              <S.AboutContainer>
                <span>
                  Com nossas gruas de pelúcias e vending machines,
                  proporcionamos momentos de diversão e alegria inesquecíveis
                  para todas as idades.
                </span>
                <span>
                  Esteja onde estiver, a qualquer momento, você pode testar suas
                  habilidades, ganhar prêmios incríveis e se envolver em uma
                  experiência única de entretenimento.
                </span>
                <span>
                  Não perca essa oportunidade emocionante de se divertir ao
                  máximo. Agarre essa chance!
                </span>
              </S.AboutContainer>
              <S.ContactContainer>
                <div>
                  <FcCollaboration size={30} />
                  <h2>Contato</h2>
                </div>
                <span>Fale conosco através dos contatos abaixo:</span>
                <div>
                  <FcInvite size={30} />
                  <a className="blue" href="mailto:example@example.com">
                    sac@onlinemachine.com.br
                  </a>
                </div>
                <WppSupport />
                <S.MediaContainer>
                  <h6>Confira nossas redes sociais</h6>
                  <div>
                    <img
                      height={30}
                      alt="Instagram"
                      src={Instagram}
                      onClick={() =>
                        window.open(
                          'https://www.instagram.com/onlinemachine_',
                          '_blank'
                        )
                      }
                    />
                    <img
                      height={30}
                      alt="Tiktok"
                      src={Tiktok}
                      onClick={() =>
                        window.open(
                          'https://www.tiktok.com/@onlinemachine8',
                          '_blank'
                        )
                      }
                    />
                  </div>
                </S.MediaContainer>
              </S.ContactContainer>
              <a
                href="https://www.alabuta.com/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon type="signature" />
              </a>
            </S.Content>
          </S.Container>
        </div>
      </MenuDrawer>
    </>
  );
}

export default About;
