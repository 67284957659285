import { createContext, useCallback, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import {
  MachineContextData,
  MachineProviderProps,
  TransactionIdsProps,
  UserProps,
} from './types';
import { usePayment } from '../hooks/payment';
import { useLogin } from '../hooks/login';
import { startGetPlayerStatusJob } from '../utils/playUtils';
import { useGameTimer } from '../hooks/timer';

export const MachineContext = createContext<MachineContextData>(
  {} as MachineContextData
);

export const MachineProvider = ({ children }: MachineProviderProps) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const [status, setStatus] = useState<'O' | 'W' | 'R' | 'P'>('O');
  const [plays, setPlays] = useState(0);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [waitingList, setWaitingList] = useState(0);
  const [copyAndPastePix, setCopyAndPastePix] = useState('');
  const [pixQrCode, setPixQrCode] = useState('');
  const [user, setUser] = useState<UserProps>({} as UserProps);
  const [transactionIds, setTransactionIds] = useState<TransactionIdsProps[]>(
    []
  );
  const [gameToken, setGameToken] = useState('');
  const [moved, setMoved] = useState(false);
  const [modalScreen, setModalScreen] = useState('');
  const [feedback, setFeedback] = useState<
    'finished' | 'error' | 'timeover' | ''
  >('');
  const [showDesktopMenu, setShowDesktopMenu] = useState(false);
  const [currentPlayerName, setCurrentPlayerName] = useState('');
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [seconds, setSeconds, resetTimer] = useGameTimer(
    status === 'P' && videoIsLoaded && isActive
  );
  const [maxMoves, setMaxMoves] = useState(15);

  const startGame = useCallback(
    (seconds = 30) => {
      setIsActive(true);
      resetTimer(seconds);
      setMoved(false);
    },
    [resetTimer]
  );

  const endGame = useCallback(() => {
    setIsActive(false);
  }, []);

  const updateUserInfo = useCallback(
    <T extends keyof UserProps>(fieldName: T, newValue: UserProps[T]) => {
      setUser((prevState) => ({ ...prevState, [fieldName]: newValue }));
    },
    []
  );

  usePayment(
    user,
    transactionIds,
    setTransactionIds,
    updateUserInfo,
    setModalScreen
  );
  useLogin(user, setWaitingList, updateUserInfo, setTransactionIds);

  useEffect(() => {
    if (status === 'W' && user.id)
      startGetPlayerStatusJob(
        user.id,
        setStatus,
        setWaitingList,
        setGameToken,
        setCurrentPlayerName,
        setPlays
      );
  }, [status, user.id]);

  // useEffect(() => {
  //   if (status === 'R') {
  //     const timer = setTimeout(() => {
  //       try {
  //         gameService.postGameRefund(user.id, gameToken, 0);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //       setStatus('O');
  //       setFeedback('error');
  //
  //       setTimeout(() => {
  //         setFeedback('');
  //       }, 5000);
  //     }, 35000);
  //
  //     return () => clearTimeout(timer);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [status]);

  return (
    <MachineContext.Provider
      value={{
        showInstructions,
        setShowInstructions,
        status,
        setStatus,
        seconds,
        setSeconds,
        socket,
        setSocket,
        plays,
        setPlays,
        waitingList,
        setWaitingList,
        copyAndPastePix,
        setCopyAndPastePix,
        pixQrCode,
        setPixQrCode,
        transactionIds,
        setTransactionIds,
        updateUserInfo,
        user,
        gameToken,
        setGameToken,
        moved,
        setMoved,
        modalScreen,
        setModalScreen,
        feedback,
        setFeedback,
        showDesktopMenu,
        setShowDesktopMenu,
        currentPlayerName,
        setCurrentPlayerName,
        videoIsLoaded,
        setVideoIsLoaded,
        isActive,
        startGame,
        endGame,
        maxMoves,
        setMaxMoves,
      }}
    >
      {children}
    </MachineContext.Provider>
  );
};
