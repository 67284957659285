export const setCookieConsent = () => {
  localStorage.setItem('cookie_consent', 'true');
};

export const getCookieConsent = () => {
  return localStorage.getItem('cookie_consent') === 'true';
};

export const localStorageUtils = {
  SetItem: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },

  GetItem(key: string): string {
    return localStorage.getItem(key);
  }
}
