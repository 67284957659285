import * as S from './styles';
import { MachineContext } from '../../../context';
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

export function CamContainer() {
  const {
    waitingList,
    status,
    plays,
    showDesktopMenu,
    videoIsLoaded,
    setVideoIsLoaded,
  } = useContext(MachineContext);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const isPlaying = status === 'P';
  const isWaiting = status === 'W';
  const isReady = status === 'R';

  return (
    <S.CamContainer showMenu={showDesktopMenu}>
      <iframe
        src="https://delaysp01.logicahost.com.br:5443/onlinemachine/play.html?id=onlinemachine02"
        frameBorder={0}
        allowFullScreen={false}
        allow={'autoplay'}
        title="First Camera"
        scrolling="no"
        onLoad={() => setTimeout(() => setVideoIsLoaded(true), 4 * 1000)}
      />

      {!videoIsLoaded && isPlaying && (
        <h2>Aguarde enquanto o vídeo carrega...</h2>
      )}
      {(isPlaying || isWaiting || isReady) && isDesktop && (
        <S.CustomInformationDisplay
          playsQuantity={plays}
          position={waitingList}
        />
      )}
    </S.CamContainer>
  );
}

export default CamContainer;
